<h1 mat-dialog-title>Êtes-vous sûr de vouloir déverrouiller ce cadeau ?</h1>
<div mat-dialog-content style="max-width: 500px; font-size: 14px;  margin-bottom: 20px;">Pour déverrouiller ce cadeau, saisissez le code à deux chiffres utilisé pour lors de la réservation. </div>
<div mat-dialog-content style="max-width: 500px; font-size: 14px;  margin-bottom: 20px;">Si vous avez oublié votre code, contactez clément</div>
<mat-form-field style="width: 100%;">
    <mat-label>Code à 2 chiffres de déverrouillage</mat-label>
    <input matInput placeholder="Saisissez le code" value="" [formControl]="code">
</mat-form-field>
<p *ngIf="errorMessage" style="color: red; max-width: 500px; font-size: 16px;">{{errorMessage }}</p>
<div mat-dialog-actions>
  <button mat-raised-button color="primary" (click)="onValidate()">Valider le code</button>
  <button mat-raised-button mat-dialog-close>Annuler</button>
</div>