import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { WishlistService } from 'src/app/services/wishlist.service';


@Component({
  selector: 'app-product-lock',
  templateUrl: './product-lock.component.html',
  styleUrls: ['./product-lock.component.css']
})
export class ProductLockComponent implements OnInit {
  id: number;
  code = new FormControl('');

  constructor(
    private wishlistService: WishlistService,
    public dialogRef: MatDialogRef<ProductLockComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Object,
  ) { }

  ngOnInit(): void {
    this.id = this.data['id'];

  }

  onAccept() {
    this.wishlistService.lockWish(this.id, this.code.value)
    .then((message) => {
      this.dialogRef.close(message);
    })
  }
}
