import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import * as firebase from 'firebase/app';
import 'firebase/auth';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private router: Router
  ) { }

  signUpUser(email: string, password: string, pseudo: string) {
    return new Promise<void>((resolve, reject) => {
      firebase.default.auth().createUserWithEmailAndPassword(email, password)
      .then((user) => {
        localStorage.setItem('isConnected', 'true');
        user.user.updateProfile({ displayName: pseudo})
          .then(() => {
            localStorage.setItem('pseudo', pseudo);
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
    })
      .catch((error) => {
        var errorCode = error.code;
        var errorMessage = error.message;
        reject(errorMessage);
      });
    })
  };

  signInUser(email: string, password: string){
    return new Promise<void>((resolve, reject) => {
      firebase.default.auth().signInWithEmailAndPassword(email, password)
        .then((user) => {
          localStorage.setItem('isConnected', 'true');
          localStorage.setItem('pseudo', user.user.displayName);
          console.log(user.user.displayName);
          this.router.navigate(['']);
          resolve();
        })
        .catch((error) => {
          reject(error);
        })
    })
  }

  signOutUser() {
    return new Promise<string>((resolve, reject) => {
      firebase.default.auth().signOut()
      .then(() => {
        localStorage.removeItem('isConnected');
        localStorage.removeItem('pseudo');
        resolve("User has been signed out");
      })
      .catch((error) => {
        reject(error);
      })
    })
  }
}
