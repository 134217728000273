<mat-card class="example-card mx-auto" >
  <button *ngIf="isAdmin" mat-icon-button class="btn-menu" [matMenuTriggerFor]="menu" aria-label="button with a menu">
    <mat-icon style="font-size: 18px; color: gray">more_vert</mat-icon>
  </button>
  <mat-menu #menu="matMenu">
    <button mat-menu-item (click)="openDialogNewProduct()">
      <mat-icon>create</mat-icon>
      <span>Modifier</span>
    </button>
    <button mat-menu-item (click)="remove()">
      <mat-icon>delete</mat-icon>
      <span>Supprimer</span>
    </button>
  </mat-menu>
    <mat-card-header>
      <mat-card-title style="font-weight: 500; font-size: 16px;">{{ name }}</mat-card-title>
      <mat-card-subtitle style="margin-bottom: 6px;">{{ brand }}</mat-card-subtitle>
    </mat-card-header>
    <mat-divider></mat-divider>
    <img mat-card-image [ngClass]="{'photo-locked': locked}" [src]="imageUrl || 'https://material.angular.io/assets/img/examples/shiba2.jpg'" class="photo" alt="Photo">
    <mat-divider></mat-divider>
    <mat-card-content [ngClass]="{'div-locked': locked}" style="margin-top: 10px;">
      <p *ngIf="description" class="pourquoi">Un peu plus de précisions:</p>
      <p class="response">
        {{ description }}
      </p>
      <p *ngIf="reason" class="pourquoi">Pourquoi ?</p>
      <p class="response">
        {{ reason }}
      </p>
    </mat-card-content>
    <mat-card-actions class="mx-auto"  style="position: absolute; bottom: 10px; margin: auto; width: 90%; text-align: center;">
      <div class="mx-auto" [ngClass]="{'div-locked': locked}" style="width: 100%; margin-top: 5px; display: flex; text-align: center;">
        <a [href]="link" target="_blank" class="btn-google" *ngIf="link" ><button mat-raised-button class="button" [ngClass]="{'btn-locked': locked}" [disabled]="!link" color="primary" matTooltip="Cliquez pour voir un exemple qui nous plaît">Exemple</button></a>
        <a [href]="googleQueryUrl" target="_blank" class="btn-google" ><button mat-raised-button [ngClass]="{'btn-locked': locked}" class="button" color="primary"  matTooltip="Cliquez pour voir des exemples sur Google">{{link ? 'Google': 'Voir sur Google'}}</button></a>
      </div>
      <div class="mx-auto" style="width: 100%; margin-top: 5px; display: flex; text-align: center;">
            <button 
              mat-raised-button 
              class="button mx-auto button-offrir" 
              color="accent" 
              [ngClass]="{'locked': locked}"
              [disabled]="locked"
              [matTooltip]="locked ? 'Cadeau vérouillé' : 'Cliquez pour vérouiller ce cadeau'" 
              (click)="openDialogProductLock()"
              >
              {{ locked ? 'Ce cadeau est vérouillé' : 'J\'offre ce cadeau !'}}
            </button>
            <button
              *ngIf="locked"
              mat-raised-button
              color="primary"
              class="button-unlock mx-auto"
              style="width: 5%;"
              matTooltip="Cliquez ici pour déverrouiller le cadeau si vous avez changé davis"
              (click)="openDialogProductUnlock()"
            >
            <mat-icon>
              lock_open
            </mat-icon>
            </button>
      </div>
    </mat-card-actions>
  </mat-card>

