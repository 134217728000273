import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { WishlistService } from 'src/app/services/wishlist.service';

@Component({
  selector: 'app-product-list',
  templateUrl: './product-list.component.html',
  styleUrls: ['./product-list.component.css']
})
export class ProductListComponent implements OnInit {
  wishList: any;
  listLoaded: boolean = false;
  userName: string;

  constructor(
    private route: ActivatedRoute,
    private wishlistService: WishlistService,
    ) { }

  ngOnInit(): void {

    this.route.params.subscribe(params => {
      this.listLoaded = false;
      this.userName = '';
      let user = params.group;
      this.wishList= {};
      if (user == "najwa&clement") {
        this.userName = "Najwa & Clément"
      }
      else if (user == "camille&mia") {
        this.userName = "Camille & Mia"
      }
      else if (user == "loan") {
        this.userName = "loan";
      }
      else if (user == "tony&fanny") {
        this.userName = "Tony & Fanny";
        user = "Tony & Fanny";
      }


      localStorage.setItem('user', user);

      this.wishlistService.getWishList(user)
      .then((wishlist) => {
        this.wishList = wishlist;
        this.listLoaded = true;
      })
      .catch((error) => {
        console.log(error);
      })
    })

  }

  refresh() {
    this.ngOnInit();
  }
}
