<mat-toolbar color="primary">
        <button mat-icon-button  (click)="sidenav.toggle()" class="toggle-btn">
            <span *ngIf="!sidenav.opened" class="material-icons" style="color: white">menu</span>
            <span *ngIf="sidenav.opened" class="material-icons" style="color: white">close</span>
        </button>
        <img routerLink="/" src="assets/christmas-hat.png" style="cursor: pointer;">
        <h1 class="site-title" routerLink="/">Noël</h1>
        <div class="div-links">
            <a class="navigation-item" routerLink="/camille&mia" routerLinkActive="active-link"> Camille & Mia </a>
            <a class="navigation-item" routerLink="/najwa&clement" routerLinkActive="active-link"> Najwa & Clément </a>
            <a class="navigation-item" routerLink="/loan" routerLinkActive="active-link"> Loan </a>
            <a class="navigation-item" routerLink="/tony&fanny" routerLinkActive="active-link"> Tony & Fanny </a>
        </div>
        <span class="spacer"></span>
        <div class="div-button">
            <button *ngIf="!isConnected" mat-raised-button color="accent" routerLink="/login">Se connecter</button>
            <!-- <p *ngIf="isConnected" style="font-size: 14px; margin: auto 20px;">Hello, {{ pseudo }}</p> -->
            <button *ngIf="isAdmin" mat-raised-button color="warn" class="btn-header" (click)="openDialog()">Ajouter un cadeau à la liste</button>
            <button *ngIf="isConnected" mat-raised-button color="warn" (click)="onSignOut()">Déconnexion</button>
        </div>
</mat-toolbar>
<mat-drawer-container class="example-container" autosize>
    <mat-drawer #sidenav class="example-sidenav mx-auto" mode="side">
        <div class="div-button-mobile mx-auto">
            <button mat-raised-button color="primary"  routerLink="/camille&mia" class="button mx-auto"> Camille & Mia </button>
            <button mat-raised-button color="primary" routerLink="/najwa&clement" class="button mx-auto"> Najwa & Clément </button>
            <button mat-raised-button color="primary" routerLink="/loan" class="button mx-auto"> Loan</button>
            <button mat-raised-button color="primary" routerLink="/tony&fanny" class="button mx-auto"> Tony & Fanny</button>
            <button *ngIf="!isConnected" mat-raised-button color="accent" routerLink="/login" class="button mx-auto">Se connecter</button>
            <button *ngIf="isAdmin" mat-raised-button color="primary" class="button mx-auto" (click)="openDialog()">Ajouter un cadeau à la liste</button>
            <button *ngIf="isConnected" mat-raised-button color="accent" (click)="onSignOut()" class="button mx-auto">Déconnexion</button>
        </div>
    </mat-drawer>
</mat-drawer-container>
