import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { WishlistService } from 'src/app/services/wishlist.service';

@Component({
  selector: 'app-product-unlock',
  templateUrl: './product-unlock.component.html',
  styleUrls: ['./product-unlock.component.css']
})
export class ProductUnlockComponent implements OnInit {
  id: number;
  code = new FormControl('');
  errorMessage: string; 

  constructor(
    private wishlistService: WishlistService,
    public dialogRef: MatDialogRef<ProductUnlockComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Object,
  ) { }

  ngOnInit(): void {
    this.id = this.data['id'];

  }

  onValidate() {
    this.wishlistService.unLockWish(this.id, this.code.value)
      .then((message) => {
        this.dialogRef.close(message);
      })
      .catch((error) => {
        this.errorMessage = error;
        this.code.setValue('');
      })
  }

}
