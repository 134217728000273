import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NewProductComponent } from '../products/new-product/new-product.component';
import { ProductListComponent } from '../products/product-list/product-list.component';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  isConnected: string;
  isAdmin: boolean = false;
  pseudo: string;
  @Output() someEvent = new EventEmitter<string>();

  constructor(
    private authService: AuthService,
    public dialog: MatDialog

  ) { }

  ngOnInit(): void {
    this.isConnected = localStorage.getItem('isConnected');
    this.pseudo = localStorage.getItem('pseudo');
    if(["najwa&clement", "camille&mia", "loan", "Tony & Fanny"].includes(this.pseudo)) {
      this.isAdmin = true;
    }
  }

  onSignOut() {
    this.authService.signOutUser()
    .then((message) => {
      console.log(message);
      window.location.reload();
    })
    .catch((error) => {
      console.log(error);
    })
  }

  openDialog() {
    const dialogRef = this.dialog.open(NewProductComponent, {data:  {
      editMode: false,
      id: 0,
      name: '',
      brand: '',
      link: '',
      reason: '',
      description: ''
    } } );
    dialogRef.afterClosed().subscribe(result => {
      window.location.reload();
    });
  }

  callParent(): void {
    this.someEvent.next('refresh');
  }




}
