<app-header></app-header>
<div class="container-fluid row">
    <div class="col-sm-5 mx-auto"  style="margin-top: 100px;">
        <h2>Se connecter</h2>
        <div class="form-container">
            <form [formGroup]="signInForm">
                <mat-form-field appearance="fill" class="form-field">
                    <mat-label>Entrez votre adresse mail</mat-label>
                    <input matInput placeholder="pat@example.com" [formControl]="email" required >
                    <mat-error *ngIf="email.invalid">{{getErrorMessage()}}</mat-error>
                </mat-form-field>
                <mat-form-field appearance="fill" class="form-field">
                    <mat-label>Entrez votre mot de passe</mat-label>
                    <input matInput [type]="hide ? 'password' : 'text'" [formControl]="password">
                    <!-- <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide"> -->
                    <mat-icon (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide" style="position: absolute; right: 10px; cursor: pointer;">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                    <!-- </button> -->
                </mat-form-field>
                <button mat-raised-button color="primary" type="submit" style="width: 100%;" [disabled]="signInForm.invalid" (click)="onSignIn()"><span *ngIf="!showSignInSpinner">Se connecter</span><mat-spinner *ngIf="showSignInSpinner" color="primary" diameter="25" style="margin: auto auto;"></mat-spinner></button>
            </form>
            <p class="text-danger">{{ errorMessage }}</p>
        </div>
    </div>
    <div class="col-sm-5 mx-auto" style="margin-top: 100px;">
        <h2>S'inscrire</h2>
        <form [formGroup]="signUpForm">
            <div class="example-container">
                <mat-form-field appearance="fill" style="width: 100%;">
                  <mat-label>Entrez votre mail</mat-label>
                  <input matInput placeholder="john@doe.com" [formControl]="emailSignUp" style="width: 100%;" required>
                </mat-form-field>
                <mat-form-field appearance="fill" style="width: 100%;">
                  <mat-label>Entrez votre pseudo</mat-label>
                  <input matInput placeholder="johndoe" [formControl]="pseudo" style="width: 100%;" required>
                </mat-form-field>
              </div>
              <div class="example-container">
                <mat-form-field appearance="fill" style="width: 100%;">
                  <mat-label>Entrez votre mot de passe</mat-label>
                  <input matInput [type]="hide ? 'password' : 'text'" [formControl]="passwordSignUp" required>
                  <button mat-icon-button matSuffix (click)="hide2 = !hide2" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide2"><span class="material-icons">{{hide2 ? 'visibility_off' : 'visibility'}}</span></button>
                </mat-form-field>
                <mat-form-field appearance="fill" style="width: 100%;">
                  <mat-label>Entrez votre mot de passe à nouveau</mat-label>
                  <input matInput [type]="hide3 ? 'password' : 'text'" [formControl]="passwordSignUp2" required>
                  <button mat-icon-button matSuffix (click)="hide3 = !hide3" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide3"><span class="material-icons">{{hide3 ? 'visibility_off' : 'visibility'}}</span></button>
                </mat-form-field>
              </div>
            <button mat-raised-button type="submit" style="width: 100%; margin-left: 2px;" [disabled]="signUpForm.invalid || arePasswordsDifferent()" (click)="onSignUp()"><span *ngIf="!showSignUpSpinner">S'inscrire</span><mat-spinner *ngIf="showSignUpSpinner" diameter="25" style="margin: auto auto;"></mat-spinner></button>
        </form>
        <p class="text-danger">{{ errorMessage2 }}</p>
    </div>
</div>