<app-header></app-header>
<div class="container-fluid" style="margin-top: 20px;">
    <div class="row">
        <div class="col-sm-6 mx-auto">
            <mat-card style="max-width: 600px; margin: auto">
                <mat-card-header style="text-align: center!important; font-family: 'Montserrat', sans-serif;">
                    <mat-card-title >
                        <h2 style="text-align: center">Tant crie-t-on Noël qu'il vient</h2>
                    </mat-card-title>
                </mat-card-header>
                <mat-card-content style="text-align: justify;">
                    <p>La joie de se retrouver à Noël donne du baume au coeur et nous fournit l'enegie suffisante pour résister au froid de l'hiver.</p>
                    <p>Nous vous proposons ces listes qui, nous en sommes convaincus, trouveront aisément leur utilité. L'idée est de vous faciliter la tâche pas si évidente des cadeaux de Noël, en vous assurant de nous offrir quelque chose dont nous avons besoin, tout en limitant le gaspillage. C'est beau.</p>
                    <p>On se voit très vite, avec nos plus beaux sourires et pulls de Noël, pour retrouver la joie d'être ensembles.</p>
                    <p>Vous pourrez voir sur ce site des idées de cadeaux, avec quelques élements descriptifs, des exempls concrets, et un bouton pour réserver le cadeau.</p>
                    <p style="text-align: right;">Avec <img src="assets/heart.png"></p>
                </mat-card-content>
                <mat-card-actions style="text-align: center;">
                    <button mat-raised-button color="primary" routerLink="/camille&mia" style="margin-top: 4px; width: 90%; font-family: 'Montserrat', sans-serif; font-size: 16px;">Voir la liste de Camille & Mia</button>
                    <button mat-raised-button color="primary" routerLink="/najwa&clement" style="margin-top: 4px; width: 90%; font-family: 'Montserrat', sans-serif; font-size: 16px;">Voir la liste de Najwa & Clément</button>
                    <button mat-raised-button color="primary" routerLink="/loan" style="margin-top: 4px; width: 90%; font-family: 'Montserrat', sans-serif; font-size: 16px;">Voir la liste de Loan</button>
                    <button mat-raised-button color="primary" routerLink="/tony&fanny" style="margin-top: 4px; width: 90%; font-family: 'Montserrat', sans-serif; font-size: 16px;">Voir la liste de Tony & Fanny</button>
                </mat-card-actions>
            </mat-card>
        </div>
    </div>
</div>
