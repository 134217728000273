import { Component, Input, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { WishlistService } from 'src/app/services/wishlist.service';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

/**
 * A data objec to be injected in the product form
 *
 * @export
 * @interface DialogData
 */
export interface DialogData {
  editMode: boolean
  id: number
  name: string
  brand: string
  reason: string
  link: string
  description: string
  imageUrl: string
}


@Component({
  selector: 'app-new-product',
  templateUrl: './new-product.component.html',
  styleUrls: ['./new-product.component.css']
})
export class NewProductComponent implements OnInit {

  newProductForm: FormGroup;
  message: string; 
  errorMessage: string;
  @Input() editMode: boolean; 
  @Input() name: string;
  @Input() brand: string;
  @Input() link: number;
  @Input() reason: string;
  @Input() imageUrl: string; 

  constructor(
    private formBuilder: FormBuilder, 
    private wishlistService: WishlistService,
    public dialogRef: MatDialogRef<NewProductComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
) { }

  ngOnInit(): void {
    console.log(this.data);
    this.editMode = this.data.editMode;
    this.errorMessage = ''; 
    this.message = ''; 
    this.newProductForm = this.formBuilder.group({
      name: this.data.name || '', 
      brand: this.data.brand || '', 
      link: this.data.link || '', 
      reason: this.data.reason || '',
      description: this.data.description, 
      imageUrl: this.data.imageUrl || ''
    })
  }

  onSubmitForm() {
    let name = this.newProductForm.get('name').value; 
    let brand = this.newProductForm.get('brand').value;
    let reason = this.newProductForm.get('reason').value;
    let link = this.newProductForm.get('link').value;
    let description = this.newProductForm.get('description').value;
    let imageUrl = this.newProductForm.get('imageUrl').value;
    if(this.editMode) {
      this.wishlistService.editWish(this.data.id, name, brand, reason, link, description, imageUrl)
        .then((message) => {
          this.message = message; 
          this.dialogRef.close();
        })
        .catch((error) => {
          this.errorMessage = error; 
        })
    }
    else {
      this.wishlistService.createNewWish(name, brand, reason, link, description, imageUrl)
      .then((message) => {
        this.message = message;
        this.dialogRef.close();
      })
      .catch((error) => {
        this.errorMessage = error;
      })
    }

  }

}
