import { Component } from '@angular/core';
import * as firebase from 'firebase/app';
import 'firebase/app'
import 'firebase/analytics'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'NOEL';

  constructor() {
    const firebaseConfig = {
      apiKey: "AIzaSyB9w1A_BN2q7E_Ee2RDtkEo4GOjBK6cJ5U",
      authDomain: "noel-2020b9.firebaseapp.com",
      databaseURL: "https://noel-2020b9.firebaseio.com",
      projectId: "noel-2020b9",
      storageBucket: "noel-2020b9.appspot.com",
      messagingSenderId: "739184141749",
      appId: "1:739184141749:web:62e62ce86d2e25b2daa5fc",
      measurementId: "G-DQK4S9GNLG"
    };

    firebase.default.initializeApp(firebaseConfig);

    firebase.default.analytics()
  }
}
