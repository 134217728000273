<h1 mat-dialog-title>Êtes-vous sûr de vouloir réserver ce cadeau ? </h1>
<div mat-dialog-content style="max-width: 500px; font-size: 14px;  margin-bottom: 20px;">Si vous validez alors ce cadeau sera indisponible dans la liste.</div>
<div mat-dialog-content style="max-width: 500px; font-size: 14px;  margin-bottom: 20px;">Saisissez ci-dessous un code personnel de reservation qui vous sera demandé pour déverrouiller le cadeau en cas de changement d'avis.</div>
<mat-form-field style="width: 100%">
  <mat-label>Saisissez un code de reservation à 2 chiffres</mat-label>
  <input matInput placeholder="Code à 2 chiffres" value="" [formControl]="code">
</mat-form-field>
<div mat-dialog-actions>
  <button mat-raised-button color="primary" (click)="onAccept()">J'ai noté mon code, je réserve !</button>
  <button mat-raised-button mat-dialog-close>Annuler</button>
</div>