import { Injectable } from '@angular/core';
import * as firebase from 'firebase/app';
import 'firebase/database'

@Injectable({
  providedIn: 'root'
})
export class WishlistService {

  constructor() { }

  createNewWish(name: string, brand: string, reason: string, link: string, description: string, imageUrl: string) {
    return new Promise <string> ((resolve, reject) => {

      let pseudo = localStorage.getItem('pseudo');
      if(pseudo == 'undefined' || !pseudo) {
        reject("No pseudo found. Unable to continue request"); 
      }
       let wish = {}; 
       wish = {
         name: name, 
         brand: brand,
         reason: reason, 
         link: link,
         description: description,
         imageUrl: imageUrl
       }

       let id = Math.floor(100000000 + Math.random() * 900000000);

      firebase.default.database().ref('/users/' + pseudo + '/wishlist/' + id).set(wish)
        .then(() => {
          resolve("Wish added to wishlist"); 
        })
        .catch((error) => {
          reject(error);
        })
    })
  }

  /**
   *
   *
   * @param {number} id - The id of the wish
   * @param {string} name - The name of the wish
   * @param {string} brand - The brand of the wish
   * @param {string} reason - The reason of the wish
   * @param {string} link - The link of the wish
   * @param {string} description - The description of the wish
   * @param {string} imageUrl - The image url of the wish
   * @return {*} 
   * @memberof WishlistService
   */
  editWish(id: number, name: string, brand: string, reason: string, link: string, description: string, imageUrl: string ) {
    return new Promise<string>((resolve, reject) => {
      let pseudo = localStorage.getItem('pseudo');
      if(pseudo == 'undefined' || !pseudo) {
        reject("No pseudo found. Unable to continue request"); 
      }
      let wish = {
        name: name, 
        brand: brand,
        reason: reason, 
        link: link,
        description: description, 
        imageUrl: imageUrl
      }
      firebase.default.database().ref('/users/' + pseudo + '/wishlist/' + id).set(wish)
        .then(() => {
          resolve("Le cadeau a été modifié");
        })
        .catch((error) => {
          reject(error); 
        })
    })
  }

  lockWish(id: number, code: number) {
    return new Promise<string>((resolve, reject) => {
      let user = localStorage.getItem('user');

      firebase.default.database().ref(`/users/${user}/wishlist/${id}/locked`).set(true)
        .then(() => {
          firebase.default.database().ref(`/users/${user}/wishlist/${id}/lockedCode`).set(code)
            .then(() => {
              resolve(`Le cadeau a été vérouillé avec le code ${code}`);
            })
            .catch((error) => {
              reject(error);
            })
        })
        .catch((error) => {
          reject(error); 
        })
    })
  }

  unLockWish(id: number, code: number) {
    return new Promise<string>((resolve, reject) => {
      let user = localStorage.getItem('user');
      
      firebase.default.database().ref(`/users/${user}/wishlist/${id}/lockedCode`).on('value', (snapshot) => {

        if (<number> snapshot.val() == code) {
          firebase.default.database().ref(`/users/${user}/wishlist/${id}/locked`).set(false)
          .then(() => {
            resolve("Le cadeau a été dévérouillé")
          })
          .catch((error) => {
            reject(error); 
          })
        }
        else {
          reject("Le code est erroné. Veuillez réessayer avec un nouveau code.")
        }
      })
    })
  }

  getWishList(name: string) {
    return new Promise((resolve, reject) => {
      firebase.default.database().ref('/users/' + name + '/wishlist/').on('value', (snapshot) => {
        resolve(snapshot.val());
      });
    })
  }


  removeWish(id: number) {
    return new Promise ((resolve, reject) => {
      let pseudo = localStorage.getItem('pseudo');
      if(pseudo == 'undefined' || !pseudo) {
        reject("No pseudo found. Unable to continue request"); 
      }
      firebase.default.database().ref('users/' + pseudo + '/wishlist/' + id).remove()
      .then(() => {
        resolve("Cadeau supprimé");
      })
      .catch((error) => {
        reject(error);
      })
    })
  }


}
