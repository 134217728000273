<div class="container-fluid" class="christmas-background">
    <div class="row justify-content-center product-list">
        <div *ngIf="!listLoaded" class="spinner mx-auto">
            <mat-spinner></mat-spinner>
        </div>
        <ng-container *ngIf="listLoaded" class="">
            <div class="col-sm-12 mx-auto">
                <h2 class="list-title">Bienvenue sur la liste de {{ userName | titlecase }}</h2>
            </div>
            <div *ngFor="let wish of wishList | keyvalue;" class="col-12 col-sm-6  col-md-4 col-xl-3 mx-auto ">
                <app-product-details 
                (someEvent)="refresh()" 
                [id]="wish.key"
                [name]="wish.value.name" 
                [brand]="wish.value.brand" 
                [reason]="wish.value.reason" 
                [link]="wish.value.link" 
                [description]="wish.value.description" 
                [imageUrl]="wish.value.imageUrl"
                [locked]="wish.value.locked"
                ></app-product-details>
            </div>
        </ng-container>
    </div>
</div> 