<mat-card>
    <mat-card-header>
        <mat-card-title>{{ editMode ? 'Modifier le cadeau'  :' Ajouter un cadeau à ma liste de souhaits'}}</mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <form [formGroup]="newProductForm">
            <p>
                <mat-form-field appearance="legacy" class="form-field">
                    <mat-label>Nom du produit</mat-label>
                    <input matInput placeholder="Ex: Lot de casserolles en inox induction" formControlName="name">
                    <mat-icon matSuffix>sentiment_very_satisfied</mat-icon>
                    <mat-hint>Essayer de mettre une référence si souhaité</mat-hint>
                </mat-form-field>
            </p>
            <p>
                <mat-form-field appearance="legacy" class="form-field">
                    <mat-label>Marque</mat-label>
                    <input matInput placeholder="Ex: Arthur Martin" formControlName="brand">
                    <mat-icon matSuffix>sentiment_very_satisfied</mat-icon>
                    <mat-hint>Essayer de mettre une marque si souhaité</mat-hint>
                </mat-form-field>
            </p>
            <p>
                <mat-form-field appearance="legacy" class="form-field">
                    <mat-label>Description</mat-label>
                    <input matInput placeholder="Ex: dimensions..." formControlName="description">
                    <mat-icon matSuffix>sentiment_very_satisfied</mat-icon>
                    <mat-hint>Essayer de mettre du détail pour éviter les déconvenues</mat-hint>
                </mat-form-field>
            </p>
            <p>
                <mat-form-field appearance="legacy" class="form-field">
                    <mat-label>Raison</mat-label>
                    <input matInput placeholder="Ex: On n'a plus de casserolles" formControlName="reason">
                    <mat-icon matSuffix>sentiment_very_satisfied</mat-icon>
                    <mat-hint>Expliquer oourquoi vous serez heureux de recevoir ce cadeau à Noël</mat-hint>
                </mat-form-field>
            </p>
            <p>
                <mat-form-field appearance="legacy" class="form-field">
                    <mat-label>Lien vers un site marchand (optionnel)</mat-label>
                    <input matInput placeholder="Ex: https://fnac.com/..." formControlName="link">
                    <mat-icon matSuffix>sentiment_very_satisfied</mat-icon>
                    <mat-hint></mat-hint>
                </mat-form-field>
            </p>
            <p>
                <mat-form-field appearance="legacy" class="form-field">
                    <mat-label>Lien vers une image (optionnel)</mat-label>
                    <input matInput placeholder="Ex: https://fnac.com/..." formControlName="imageUrl">
                    <mat-icon matSuffix>sentiment_very_satisfied</mat-icon>
                    <mat-hint>Une image de chien sera affichée par défaut</mat-hint>
                </mat-form-field>
            </p>
        </form>
    </mat-card-content>
    <mat-card-actions>
        <button mat-raised-button color="primary" (click)="onSubmitForm()">{{ editMode ? 'Enregistrer les modifications' : 'Ajouter le cadeau à la liste'}}</button>
        <button mat-raised-button color="primary" (click)="ngOnInit()">Réinitialiser le formulaire</button>
    </mat-card-actions>
    <p color="primary" *ngIf="message">{{ message }}</p>
    <p color="warn" *ngIf="errorMessage">{{ errorMessage }}</p>
</mat-card>
