import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { WishlistService } from 'src/app/services/wishlist.service';
import { NewProductComponent } from '../new-product/new-product.component';
import { ProductListComponent } from '../product-list/product-list.component';
import { ProductLockComponent } from '../product-lock/product-lock.component';
import { ProductUnlockComponent } from '../product-unlock/product-unlock.component';

@Component({
  selector: 'app-product-details',
  templateUrl: './product-details.component.html',
  styleUrls: ['./product-details.component.css']
})
export class ProductDetailsComponent implements OnInit {
  @Output() someEvent = new EventEmitter<string>();
  @Input() name: string;
  @Input() brand: string;
  @Input() link: string;
  @Input() reason: string;
  @Input() description: string;
  @Input() imageUrl: string;
  @Input() id: number;
  @Input() locked: boolean;

  googleQueryUrl: string;
  isConnected: string;
  pseudo: string;
  isAdmin: boolean;
  constructor(
    public dialog: MatDialog,
    private wishlistService: WishlistService,
    private _snackBar: MatSnackBar
  ) { }

  ngOnInit(): void {

    this.isConnected = localStorage.getItem('isConnected');
    this.pseudo = localStorage.getItem('pseudo');
    if(["najwa&clement", "camille&mia","loan", "Tony & Fanny"].includes(this.pseudo)) {
      this.isAdmin = true;
    }

     let fullName = this.name + '+'  + this.brand;
     this.googleQueryUrl = "https://www.google.com/search?q=" + fullName.replace(' ', '+');

  }

  openDialogNewProduct() {
    const dialogRef = this.dialog.open(NewProductComponent, {data:  {
      editMode: 'true',
      id: this.id,
      name: this.name,
      brand: this.brand,
      link: this.link,
      reason: this.reason,
      description: this.description,
      imageUrl: this.imageUrl
    } } );

    dialogRef.afterClosed().subscribe(result => {
      // console.log(`Dialog result: ${result}`);
      this.callParent();
    });
  }

  openDialogProductLock() {
    const dialogRefLock = this.dialog.open(ProductLockComponent, {data:  {
      id: this.id
      // name: this.name,
    } }
    );

    dialogRefLock.afterClosed().subscribe(result => {
      this.callParent();
      if(result) {
        this._snackBar.open(result, "Fermer", {
          duration: 5000,
        });
      }

    });
  }

  openDialogProductUnlock() {
    const dialogRefUnLock = this.dialog.open(
        ProductUnlockComponent,
        {
          data:  {
            id: this.id
          }
        }
    )

    dialogRefUnLock.afterClosed().subscribe(result => {
      this.callParent();
      if(result) {
        this._snackBar.open(result, "Fermer", {
          duration: 5000,
        });
      }

    })
  }


  callParent(): void {
    this.someEvent.next('refresh');
  }

  remove() {
    this.wishlistService.removeWish(this.id)
    .then((message) => {
      this.callParent();
    })
    .catch((error) => {
      alert(error);
    })
  }

}
